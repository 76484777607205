import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeft, Check, Phone, Loader2, Shield, Star } from 'lucide-react';
import { usePlan } from '../contexts/PlanContext';
import { useUser } from '@clerk/clerk-react';
import SessionStorageUtil from '../utils/SessionStorageUtil';
import { showError } from '../components/Notifications';

// SubscriptionCard component
const SubscriptionCard = ({ 
  title, 
  price, 
  features, 
  isPopular, 
  onSelect, 
  isLoading,
  currency = 'USD'
}) => {
  const cardId = `plan-${title.toLowerCase()}`;
  
  return (
    <div 
      role="region"
      aria-labelledby={`${cardId}-title`}
      className={`bg-white rounded-lg p-4 sm:p-6 shadow-md relative h-full flex flex-col ${
        isPopular ? 'border-2 border-[#da018e]' : ''
      }`}
    >
      {/* Popular plan badge */}
      {isPopular && (
        <div 
          className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-[#da018e] text-white px-4 py-1 rounded-full text-sm font-medium"
          aria-label="Most popular plan"
        >
          Most Popular
        </div>
      )}

      {/* Plan title and price */}
      <div className="mb-8">
        <h3 
          id={`${cardId}-title`}
          className="text-lg sm:text-xl font-bold mb-2 flex items-center gap-2"
        >
          {title}
          {isPopular && (
            <Star 
              className="w-4 h-4 text-[#da018e] fill-[#da018e]" 
              aria-hidden="true"
            />
          )}
        </h3>
        <div className="text-2xl sm:text-3xl font-bold mb-4">
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency
          }).format(price)}
          <span className="text-lg sm:text-xl font-normal">/mo</span>
        </div>
      </div>
      
      {/* Plan features */}
      <div className="flex-grow">
        <ul className="space-y-3 mb-8" aria-label={`Features for ${title} plan`}>
          {features.map((feature, index) => (
            <li key={index} className="flex items-center gap-2">
              <Check 
                className="w-4 h-4 sm:w-5 sm:h-5 text-[#da018e] flex-shrink-0" 
                aria-hidden="true"
              />
              <span className="text-sm sm:text-base">{feature}</span>
            </li>
          ))}
        </ul>
      </div>

      {/* Select plan button */}
      <button
        onClick={onSelect}
        disabled={isLoading}
        aria-busy={isLoading}
        className="w-full bg-[#da018e] text-white py-2 rounded-full font-semibold border-2 border-[#da018e] hover:bg-white hover:text-[#da018e] transition-colors text-sm sm:text-base disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-[#da018e] focus:ring-offset-2"
      >
        {isLoading ? (
          <span className="flex items-center justify-center gap-2">
            <Loader2 className="w-5 h-5 animate-spin" aria-hidden="true" />
            Processing...
          </span>
        ) : (
          'Get started now'
        )}
      </button>
    </div>
  );
};

// Main SubscriptionPage component
const SubscriptionPage = () => {
  const [loading, setLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFixed, setIsFixed] = useState(false);
  
  const navigate = useNavigate();
  const { selectedPlan, setSelectedPlan } = usePlan();
  const { isSignedIn } = useUser();

  // Simulate initial page loading
  useEffect(() => {
    setTimeout(() => setIsPageLoading(false), 1000);
  }, []);

  // Handle scroll for fixed header
  useEffect(() => {
    const handleScroll = () => {
      setIsFixed(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Define subscription plans
  const plans = [
    {
      title: 'Basic',
      price: 24.99,
      features: [
        'Unlimited items',
        'Training Module',
        'In-store pickup',
        '24/7 Support',
        'Mobile app access',
        'Basic reporting'
      ],
      priceId: 'price_1QEErPHn9X1Cu7esRQHihDO5' // Replace with your actual Stripe Price ID
    },
    {
      title: 'Pro',
      price: 44.99,
      features: [
        'Unlimited items',
        'Training Module',
        'Inventory Module',
        'Priority Support',
        'Advanced Analytics',
        'Custom Branding'
      ],
      priceId: 'price_1QRCTPHn9X1Cu7esUo5jsMxn', // Replace with your actual Stripe Price ID
      isPopular: true,
    },
  ];

  // Handle plan selection
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    SessionStorageUtil.setItem('selectedPlan', plan);
    if (isSignedIn) {
      navigate('/payment');
    } else {
      navigate('/sign-up');
    }
  };

  // Render loading skeleton
  if (isPageLoading) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <Loader2 className="w-8 h-8 animate-spin text-[#da018e]" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 text-black font-sans">
      {/* Header */}
      <header className={`bg-white p-4 sm:p-6 ${
        isFixed ? 'fixed top-0 left-0 right-0 shadow-md z-50' : ''
      }`}>
        <div className="max-w-4xl mx-auto flex justify-between items-center">
          <Link 
            to="/" 
            className="text-[#da018e] p-2 hover:bg-gray-200 rounded-full transition-colors"
            aria-label="Go back to home page"
          >
            <ArrowLeft className="w-5 h-5 sm:w-6 sm:h-6" />
          </Link>
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/6/6c/Planet_Smoothie_logo.svg"
            alt="Planet Smoothie Logo"
            className="h-8 sm:h-12"
            width="96"
            height="48"
          />
          <div className="w-5 h-5 sm:w-6 sm:h-6"></div>
        </div>
      </header>

      <main className="max-w-4xl mx-auto p-4 sm:p-6 pt-20">
        {/* Page title and description */}
        <section className="text-center mb-8 sm:mb-12">
          <h1 className="text-3xl sm:text-4xl font-bold mb-3 sm:mb-4">
            Choose Your Smoothie Plan
          </h1>
          <p className="text-lg sm:text-xl text-gray-600">
            Enjoy delicious, healthy smoothies with our flexible subscription options.
          </p>
        </section>

        {/* Subscription plans */}
        <section 
          className="grid sm:grid-cols-2 gap-6 sm:gap-8 mb-8 sm:mb-12"
          role="region"
          aria-label="Subscription plans"
        >
          {plans.map((plan, index) => (
            <SubscriptionCard
              key={index}
              {...plan}
              isLoading={loading && plan.title === 'Pro'}
              onSelect={() => handlePlanSelect(plan)}
            />
          ))}
        </section>

        {/* Contact sales section */}
        <section 
          className="bg-white rounded-lg p-4 sm:p-6 shadow-md"
          role="complementary"
          aria-label="Contact sales"
        >
          <div className="flex items-center mb-3 sm:mb-4">
            <Phone className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-[#da018e]" />
            <h3 className="text-lg sm:text-xl font-bold">Let's chat</h3>
          </div>
          <p className="text-sm sm:text-base text-gray-600 mb-4 sm:mb-6">
            Looking for a custom plan? Let's hop on a quick call to create the perfect smoothie subscription for you.
          </p>
          <button 
            className="w-full bg-white text-[#da018e] py-2 rounded-full font-semibold border-2 border-[#da018e] hover:bg-[#da018e] hover:text-white transition-colors text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-[#da018e] focus:ring-offset-2"
            aria-label="Schedule a consultation call"
          >
            Book a call
          </button>
        </section>

        {/* Secure checkout info */}
        <div className="mt-8 flex items-center justify-center gap-2 text-sm text-gray-600">
          <Shield className="w-4 h-4" aria-hidden="true" />
          <span>Secure checkout powered by Stripe</span>
        </div>
      </main>

      {/* Footer */}
      <footer className="mt-8 sm:mt-12 text-center text-xs sm:text-sm text-gray-600 p-4">
        © {new Date().getFullYear()} Planet Smoothie. All rights reserved.
      </footer>
    </div>
  );
};

export default SubscriptionPage;

