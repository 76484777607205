import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import { createClient } from '@supabase/supabase-js';
import { Loader2 } from 'lucide-react';
import SessionStorageUtil from '../utils/SessionStorageUtil';
import { showError, showSuccess } from '../components/Notifications';

const PostPaymentPage = () => {
  const navigate = useNavigate();
  const { isSignedIn, user } = useUser();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const handlePostPayment = async () => {
      if (isSignedIn && user) {
        try {
          // Retrieve pending user data from sessionStorage
          const pendingUserData = SessionStorageUtil.getItem('pendingUserData');

          if (pendingUserData) {
            // Create an authenticated Supabase client
            const supabase = createClient(
              process.env.REACT_APP_SUPABASE_URL,
              process.env.REACT_APP_SUPABASE_ANON_KEY
            );

            // Verify subscription in Supabase
            const { data, error } = await supabase
              .from('user_plan')
              .select('*')
              .eq('email', user.primaryEmailAddress.emailAddress)
              .single();

            if (error) {
              console.error('Supabase error:', error);
              throw new Error('Failed to verify subscription. Please contact support.');
            }

            if (!data) {
              throw new Error('Subscription not found. Please contact support.');
            }

            // Clear pending user data from sessionStorage
            SessionStorageUtil.removeItem('pendingUserData');
            SessionStorageUtil.removeItem('selectedPlan');
            SessionStorageUtil.removeItem('signUpData');

            showSuccess('Your subscription has been successfully set up!');

            // Redirect to dashboard
            setTimeout(() => navigate('/dashboard'), 2000);
          } else {
            throw new Error('No pending user data found');
          }
        } catch (err) {
          console.error('Error processing payment:', err);
          setError(err.message || 'There was an error processing your payment. Please contact support.');
          showError(err.message || 'Error processing payment. Please try again or contact support.');
        } finally {
          setIsProcessing(false);
        }
      }
    };

    handlePostPayment();
  }, [isSignedIn, user, navigate]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-center">
          <h1 className="text-3xl font-bold mb-4">Error</h1>
          <p className="text-xl text-red-600">{error}</p>
          <button
            onClick={() => navigate('/subscription')}
            className="mt-4 px-4 py-2 bg-[#da018e] text-white rounded hover:bg-[#da018e]/90 transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="text-center">
        <h1 className="text-3xl font-bold mb-4">
          {isProcessing ? "Processing your payment..." : "Payment Successful!"}
        </h1>
        <p className="text-xl">
          {isProcessing ? "Please wait while we set up your account." : "Your account has been set up successfully."}
        </p>
        {isProcessing && (
          <Loader2 className="w-8 h-8 animate-spin text-[#da018e] mx-auto mt-4" />
        )}
        {!isProcessing && (
          <button
            onClick={() => navigate('/dashboard')}
            className="mt-4 px-4 py-2 bg-[#da018e] text-white rounded hover:bg-[#da018e]/90 transition-colors"
          >
            Go to Dashboard
          </button>
        )}
      </div>
    </div>
  );
};

export default PostPaymentPage;

