import React from 'react';
import { Link } from 'react-router-dom';
import { useUser, useClerk } from '@clerk/clerk-react';
import { Download, User, CreditCard, LogOut, ArrowLeft, BookOpen } from 'lucide-react'; // Replaced duplicate User icon with BookOpen
import { useAuth } from '../hooks/useAuth';
import SkeletonLoader from '../components/SkeletonLoader';
import { createClient } from '@supabase/supabase-js';

// DashboardCard component for reusability
const DashboardCard = ({ icon: Icon, title, description, linkTo, isExternal = false }) => (
  isExternal ? (
    <a
      href={linkTo}
      target="_blank"
      rel="noopener noreferrer" // Prevent security vulnerabilities
      className="bg-white rounded-lg p-6 md:p-8 shadow-lg hover:shadow-xl transition-shadow flex items-start"
      aria-label={title} // Accessibility improvement: aria-label for better screen reader support
    >
      <Icon className="w-8 h-8 md:w-10 md:h-10 text-[#da018e] mr-4" aria-hidden="true" />
      <div>
        <h3 className="text-xl md:text-2xl font-bold text-gray-900 mb-2">{title}</h3>
        <p className="text-base md:text-lg text-gray-600">{description}</p>
      </div>
    </a>
  ) : (
    <Link
      to={linkTo}
      className="bg-white rounded-lg p-6 md:p-8 shadow-lg hover:shadow-xl transition-shadow flex items-start"
      aria-label={title} // Accessibility improvement
    >
      <Icon className="w-8 h-8 md:w-10 md:h-10 text-[#da018e] mr-4" aria-hidden="true" />
      <div>
        <h3 className="text-xl md:text-2xl font-bold text-gray-900 mb-2">{title}</h3>
        <p className="text-base md:text-lg text-gray-600">{description}</p>
      </div>
    </Link>
  )
);

const Dashboard = () => {
  const { user } = useUser();
  const { signOut } = useClerk();
  const { subscription, loading } = useAuth();

  const handleSignOut = async () => {
    await signOut();
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 md:px-8 lg:px-12">
        <SkeletonLoader />
      </div>
    );
  }

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-start py-8 px-4 sm:px-6 md:px-8 lg:px-12">
      {/* Header */}
      <header className="flex justify-between items-center mb-8 md:mb-12">
        <Link
          to="/"
          className="text-[#da018e] p-2 hover:bg-gray-200 rounded-full transition-colors"
          aria-label="Go back to home page"
        >
          <ArrowLeft className="w-6 h-6 md:w-7 md:h-7" />
        </Link>

        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6c/Planet_Smoothie_logo.svg"
          alt="Planet Smoothie Logo"
          className="h-14 md:h-16 w-auto"
          loading="lazy" // Performance improvement: Lazy load the logo
        />

        <button
          onClick={handleSignOut}
          className="text-[#da018e] p-2 hover:bg-gray-200 rounded-full transition-colors"
          aria-label="Sign out"
        >
          <LogOut className="w-6 h-6 md:w-7 md:h-7" />
        </button>
      </header>

      {/* Main content */}
      <main className="max-w-3xl mx-auto w-full">
        {/* Welcome section */}
        <section className="text-center mb-12 md:mb-16">
          <h1 className="text-3xl md:text-4xl font-extrabold text-gray-900 mb-4">
            Welcome, {user?.firstName || user?.username || 'Guest'}!
          </h1>
          <p className="text-lg md:text-xl text-gray-600">
            Manage your smoothie subscription and download our app.
          </p>
        </section>

        {/* Dashboard cards */}
        <section className="grid md:grid-cols-2 gap-6 md:gap-8 mb-12 md:mb-16">
          <DashboardCard
            icon={User}
            title="Your Profile"
            description="View and update your account information."
            linkTo="/profile"
          />
          <DashboardCard
            icon={BookOpen} // Replaced duplicate User icon with BookOpen
            title="Recipe Guide"
            description="Access the webview Recipe Guide."
            linkTo="/smoothie"
          />
          <DashboardCard
            icon={CreditCard}
            title="Subscription Details"
            description={`Manage your ${subscription?.plan || 'current'} plan (${subscription?.status || 'Unknown'}).`}
            linkTo="https://billing.stripe.com/p/login/aEU15iegNgj5f9m3cc"
            isExternal={true}
          />
        </section>

        {/* Download section */}
        <section className="bg-white rounded-xl p-8 shadow-lg text-center">
          <h3 className="text-2xl md:text-3xl font-bold text-gray-900 mb-6">
            Ready to blend on the go?
          </h3>
          <button
            className="inline-flex items-center justify-center py-3 px-8 border border-transparent 
              rounded-lg shadow-sm text-base md:text-lg font-medium text-white bg-[#da018e] 
              hover:bg-[#da018e]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 
              focus:ring-[#da018e] transition-colors"
            aria-label="Download the Progressive Web App"
          >
            <Download className="w-5 h-5 md:w-6 md:h-6 mr-2" aria-hidden="true" />
            Download PWA App
          </button>
        </section>
      </main>

      {/* Footer */}
      <footer className="mt-12 md:mt-16 text-center text-sm md:text-base text-gray-600">
        © 2024 Planet Smoothie. All rights reserved.
      </footer>
    </div>
  );
};

export default Dashboard;

