// Utility functions for input validation

const ValidationUtil = {
    // Validate email format
    isValidEmail: (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
  
    // Validate password strength
    isValidPassword: (password) => {
      // At least 8 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      return passwordRegex.test(password);
    },
  
    // Validate name (no numbers or special characters)
    isValidName: (name) => {
      const nameRegex = /^[a-zA-Z\s]+$/;
      return nameRegex.test(name);
    },
  
    // Validate plan selection
    isValidPlan: (plan) => {
      const validPlans = ['Basic', 'Pro']; // Add all valid plan names
      return validPlans.includes(plan);
    },
  
    // Validate form data
    validateForm: (formData) => {
      const errors = {};
  
      if (!formData.firstName || !ValidationUtil.isValidName(formData.firstName)) {
        errors.firstName = 'Please enter a valid first name';
      }
  
      if (!formData.lastName || !ValidationUtil.isValidName(formData.lastName)) {
        errors.lastName = 'Please enter a valid last name';
      }
  
      if (!formData.email || !ValidationUtil.isValidEmail(formData.email)) {
        errors.email = 'Please enter a valid email address';
      }
  
      if (!formData.password || !ValidationUtil.isValidPassword(formData.password)) {
        errors.password = 'Password must be at least 8 characters long and include uppercase, lowercase, number, and special character';
      }
  
      return errors;
    }
  };
  
  export default ValidationUtil;
  
  