import React, { createContext, useState, useContext, useEffect } from 'react';
import SessionStorageUtil from '../utils/SessionStorageUtil';

// Create a context for managing the selected plan
const PlanContext = createContext();

export const PlanProvider = ({ children }) => {
  // Initialize state with the plan from sessionStorage or null
  const [selectedPlan, setSelectedPlan] = useState(() => {
    return SessionStorageUtil.getItem('selectedPlan') || null;
  });

  // Update sessionStorage whenever the selected plan changes
  useEffect(() => {
    if (selectedPlan) {
      SessionStorageUtil.setItem('selectedPlan', selectedPlan);
    } else {
      SessionStorageUtil.removeItem('selectedPlan');
    }
  }, [selectedPlan]);

  return (
    <PlanContext.Provider value={{ selectedPlan, setSelectedPlan }}>
      {children}
    </PlanContext.Provider>
  );
};

// Custom hook to use the plan context
export const usePlan = () => useContext(PlanContext);

