import React, { useState, useEffect } from 'react';
import { useSignUp } from '@clerk/clerk-react';
import { useNavigate, Link } from 'react-router-dom';
import { ArrowLeft, Loader2 } from 'lucide-react';
import { usePlan } from '../contexts/PlanContext';
import SessionStorageUtil from '../utils/SessionStorageUtil';
import ValidationUtil from '../utils/ValidationUtil';
import { showError, showSuccess } from '../components/Notifications';

// Skeleton loader component
const SkeletonForm = () => (
  <div className="space-y-6 animate-pulse">
    {[...Array(4)].map((_, i) => (
      <div key={i}>
        <div className="h-4 bg-gray-200 rounded w-1/4 mb-2"></div>
        <div className="h-10 bg-gray-200 rounded w-full"></div>
      </div>
    ))}
    <div className="h-10 bg-gray-200 rounded-full w-full"></div>
  </div>
);

const SignUpPage = () => {
  const { isLoaded, signUp, setActive } = useSignUp();
  const navigate = useNavigate();
  const { selectedPlan } = usePlan();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    code: ''
  });
  
  const [errors, setErrors] = useState({});
  const [pendingVerification, setPendingVerification] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);

  // Check for selected plan and simulate initial loading
  useEffect(() => {
    if (!selectedPlan) {
      navigate('/subscription');
    }
    setTimeout(() => setIsPageLoading(false), 1000);
  }, [selectedPlan, navigate]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoaded || isSubmitting) return;

    setIsSubmitting(true);
    setErrors({});

    // Validate form data
    const validationErrors = ValidationUtil.validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsSubmitting(false);
      return;
    }

    try {
      await signUp.create({
        firstName: formData.firstName,
        lastName: formData.lastName,
        emailAddress: formData.email,
        password: formData.password,
      });

      await signUp.prepareEmailAddressVerification({ strategy: "email_code" });

      // Store sign-up data in sessionStorage
      SessionStorageUtil.setItem('signUpData', {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email
      });

      setPendingVerification(true);
      showSuccess('Verification email sent. Please check your inbox.');
    } catch (err) {
      console.error('Sign up error:', err);
      showError(err.message || 'An error occurred during sign up. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle email verification
  const handleVerification = async (e) => {
    e.preventDefault();
    if (!isLoaded || isSubmitting) return;

    setIsSubmitting(true);
    setErrors({});

    try {
      const completeSignUp = await signUp.attemptEmailAddressVerification({
        code: formData.code,
      });

      if (completeSignUp.status === "complete") {
        await setActive({ session: completeSignUp.createdSessionId });
        
        const signUpData = SessionStorageUtil.getItem('signUpData');
        const selectedPlan = SessionStorageUtil.getItem('selectedPlan');
        
        const userData = {
          userId: completeSignUp.createdUserId,
          ...signUpData,
          plan: selectedPlan.title,
          status: 'pending',
          nextBillingDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
          amount: selectedPlan.price
        };
        
        SessionStorageUtil.setItem('pendingUserData', userData);

        showSuccess('Email verified successfully!');
        navigate('/payment');
      } else {
        showError('Verification incomplete. Please try again.');
      }
    } catch (err) {
      console.error('Verification error:', err);
      showError(err.message || 'An error occurred during verification. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Link
        to="/"
        className="absolute top-4 left-4 text-[#da018e] p-2 hover:bg-gray-200 rounded-full transition-colors"
        aria-label="Go back to home page"
      >
        <ArrowLeft className="w-5 h-5" />
      </Link>

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img 
          src="https://upload.wikimedia.org/wikipedia/commons/6/6c/Planet_Smoothie_logo.svg" 
          alt="Planet Smoothie Logo" 
          className="mx-auto h-12 w-auto"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {pendingVerification ? "Verify your email" : "Create your account"}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {isPageLoading ? (
            <SkeletonForm />
          ) : !pendingVerification ? (
            <form className="space-y-6" onSubmit={handleSubmit}>
              {[
                {
                  label: "First Name",
                  name: "firstName",
                  type: "text",
                  autoComplete: "given-name"
                },
                {
                  label: "Last Name",
                  name: "lastName",
                  type: "text",
                  autoComplete: "family-name"
                },
                {
                  label: "Email address",
                  name: "email",
                  type: "email",
                  autoComplete: "email"
                },
                {
                  label: "Password",
                  name: "password",
                  type: "password",
                  autoComplete: "new-password"
                }
              ].map((field) => (
                <div key={field.name}>
                  <label 
                    htmlFor={field.name} 
                    className="block text-sm font-medium text-gray-700"
                  >
                    {field.label}
                  </label>
                  <div className="mt-1">
                    <input
                      id={field.name}
                      name={field.name}
                      type={field.type}
                      autoComplete={field.autoComplete}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#da018e] focus:border-[#da018e] sm:text-sm"
                      value={formData[field.name]}
                      onChange={handleInputChange}
                    />
                  </div>
                  {errors[field.name] && (
                    <p className="mt-2 text-sm text-red-600">{errors[field.name]}</p>
                  )}
                </div>
              ))}

              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#da018e] hover:bg-[#da018e]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#da018e] disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSubmitting ? (
                  <span className="flex items-center">
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Creating account...
                  </span>
                ) : (
                  'Create account'
                )}
              </button>
            </form>
          ) : (
            <form className="space-y-6" onSubmit={handleVerification}>
              <div>
                <label 
                  htmlFor="code" 
                  className="block text-sm font-medium text-gray-700"
                >
                  Verification Code
                </label>
                <div className="mt-1">
                  <input
                    id="code"
                    name="code"
                    type="text"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#da018e] focus:border-[#da018e] sm:text-sm"
                    value={formData.code}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#da018e] hover:bg-[#da018e]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#da018e] disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSubmitting ? (
                  <span className="flex items-center">
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Verifying...
                  </span>
                ) : (
                  'Verify Email'
                )}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;

