import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { usePlan } from '../contexts/PlanContext';
import { useUser } from '@clerk/clerk-react';
import { Loader2 } from 'lucide-react';
import SessionStorageUtil from '../utils/SessionStorageUtil';
import { showError, showSuccess } from './Notifications';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// PaymentForm component
const PaymentForm = ({ userId, plan }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const { user } = useUser();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements || !userId || !plan) {
      setError("Missing required information. Please try again.");
      setProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    // Create payment method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setProcessing(false);
      return;
    }

    try {
      // Call the serverless function to create a subscription
      const response = await fetch('/api/create-subscription', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: user.primaryEmailAddress.emailAddress,
          paymentMethodId: paymentMethod.id,
          priceId: plan.priceId, // You need to add this to your plan object
          planName: plan.title,
        }),
      });

      const { subscriptionId, clientSecret } = await response.json();

      if (response.ok) {
        // Confirm the payment
        const { error: confirmError } = await stripe.confirmCardPayment(clientSecret);

        if (confirmError) {
          throw new Error(confirmError.message);
        }

        showSuccess('Payment successful!');
        navigate('/post-payment');
      } else {
        throw new Error('Failed to create subscription');
      }
    } catch (err) {
      setError(err.message);
      showError('Payment failed. Please try again.');
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-8 space-y-6">
      <div className="rounded-md shadow-sm -space-y-px">
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
          className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-[#da018e] focus:border-[#da018e] focus:z-10 sm:text-sm"
        />
      </div>
      {error && <div className="text-red-600 text-sm mt-2">{error}</div>}
      <button
        type="submit"
        disabled={!stripe || processing}
        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#da018e] hover:bg-[#da018e]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#da018e] disabled:opacity-50"
      >
        {processing ? (
          <span className="flex items-center">
            <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
            Processing...
          </span>
        ) : (
          'Pay'
        )}
      </button>
    </form>
  );
};

// Main PaymentPage component
const Payment = () => {
  const { user } = useUser();
  const { selectedPlan } = usePlan();
  const navigate = useNavigate();
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsPageLoading(false), 1000);

    if (!user || !selectedPlan) {
      navigate('/subscription');
    }
  }, [user, selectedPlan, navigate]);

  if (isPageLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <Loader2 className="w-8 h-8 animate-spin text-[#da018e]" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="https://upload.wikimedia.org/wikipedia/commons/6/6c/Planet_Smoothie_logo.svg"
          alt="Planet Smoothie"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Complete your purchase</h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          You're signing up for the {selectedPlan.title} plan at{' '}
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(selectedPlan.price)}/month.
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Elements stripe={stripePromise}>
            <PaymentForm userId={user.id} plan={selectedPlan} />
          </Elements>
        </div>
      </div>
    </div>
  );
};

export default Payment;

