import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ClerkProvider, SignedIn, SignedOut } from '@clerk/clerk-react';
import { PlanProvider } from './contexts/PlanContext';
import SubscriptionPage from './pages/Subscription';
import SignUpPage from './pages/SignUpPage';
import Dashboard from './pages/Dashboard';
import PaymentPage from './components/Payment';
import PostPayment from './components/PostPayment';

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function App() {
  return (
    <ClerkProvider publishableKey={clerkPubKey}>
      <PlanProvider>
        <Router>
          <Routes>
            <Route path="/" element={<SubscriptionPage />} />
            <Route path="/subscription" element={<SubscriptionPage />} />
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route 
              path="/payment" 
              element={
                <SignedIn>
                  <PaymentPage />
                </SignedIn>
              } 
            />
            <Route 
              path="/post-payment" 
              element={
                <SignedIn>
                  <PostPayment />
                </SignedIn>
              } 
            />
            <Route 
              path="/dashboard" 
              element={
                <SignedIn>
                  <Dashboard />
                </SignedIn>
              } 
            />
            <Route 
              path="*" 
              element={
                <SignedIn>
                  <Navigate to="/dashboard" replace />
                </SignedIn>
              } 
            />
            <Route 
              path="*" 
              element={
                <SignedOut>
                  <Navigate to="/subscription" replace />
                </SignedOut>
              } 
            />
          </Routes>
        </Router>
      </PlanProvider>
    </ClerkProvider>
  );
}

export default App;

