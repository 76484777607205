import { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { createClient } from '@supabase/supabase-js';

export const useAuth = () => {
  const { user } = useUser();
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscription = async () => {
      if (user) {
        const supabase = createClient(
          process.env.REACT_APP_SUPABASE_URL,
          process.env.REACT_APP_SUPABASE_ANON_KEY
        );

        const { data, error } = await supabase
          .from('user_plan')
          .select('*')
          .eq('email', user.primaryEmailAddress.emailAddress)
          .single();

        if (error) {
          console.error('Error fetching subscription:', error);
        } else {
          setSubscription(data);
        }
      }
      setLoading(false);
    };

    fetchSubscription();
  }, [user]);

  return { subscription, loading };
};

