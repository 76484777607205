import CryptoJS from 'crypto-js';

// Encryption key - in a real-world scenario, this should be securely managed
const ENCRYPTION_KEY = process.env.NEXT_PUBLIC_ENCRYPTION_KEY || 'fallback-key';

// Utility functions for interacting with sessionStorage
const SessionStorageUtil = {
  // Set an item in sessionStorage
  setItem: (key, value) => {
    try {
      if (!ENCRYPTION_KEY) {
        console.error('Encryption key is not set. Unable to encrypt data.');
        return;
      }
      // Encrypt the value before storing
      const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), ENCRYPTION_KEY).toString();
      sessionStorage.setItem(key, encryptedValue);
    } catch (error) {
      console.error('Error setting sessionStorage item:', error.message);
    }
  },

  // Get an item from sessionStorage
  getItem: (key) => {
    try {
      if (!ENCRYPTION_KEY) {
        console.error('Encryption key is not set. Unable to decrypt data.');
        return null;
      }
      const encryptedValue = sessionStorage.getItem(key);
      if (encryptedValue) {
        // Decrypt the value before returning
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedValue, ENCRYPTION_KEY);
        return JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
      }
      return null;
    } catch (error) {
      console.error('Error getting sessionStorage item:', error.message);
      return null;
    }
  },

  // Remove an item from sessionStorage
  removeItem: (key) => {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing sessionStorage item:', error);
    }
  },

  // Clear all items from sessionStorage
  clear: () => {
    try {
      sessionStorage.clear();
    } catch (error) {
      console.error('Error clearing sessionStorage:', error);
    }
  }
};

export default SessionStorageUtil;

